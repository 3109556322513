import React, { useState } from 'react';
import Layout from '../../layout/Layout';
import { Card, Col, Row, Form, Modal, Button,Breadcrumb } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';
import {updateAffilationLink} from "../../service/apis"
import { toast } from 'react-toastify';

const ChangeUrl = () => {
    const navigate = useNavigate()
    const [isLoading,setIsLoading] = useState(false);
    const [validated, setvalidated] = useState(false);
    const [oldURL,setOldURL]= useState(null)
    const [newURL,setNewURL]= useState(null)
    const submitHandler = (e)=>{
        e.preventDefault()
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            e.stopPropagation();
            try {
                if ((oldURL && newURL)) {
                    setIsLoading(true);
                    const formData = new FormData();
                    formData.append('old_url', oldURL)
                    formData.append('new_url', newURL);  
                    updateAffilationLink(formData).then((data)=>{
                        console.log('data', data)
                        setIsLoading(false);
                        if (data.data.success) {
                            toast.success(data.data.message)
                            navigate("/affiliation");
                            setNewURL("")
                            setOldURL("")
                        }
                    })
                    setIsLoading(false)
                    setNewURL("")
                    setOldURL("")
                }
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false);
                setNewURL("")
                setOldURL("")
            }
        }
        else{
            setvalidated(true)
        }
    }
    return (
        <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading">
                    <h3>Change Affilation URL </h3>
                    {/* <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/change-url">Change Url</Link>
                        </Breadcrumb.Item>
                       
                    </Breadcrumb> */}
                </div>
                <div className="page-content">
                    <Form onSubmit={submitHandler} validated={validated}>
                        <Card className="radiustop-0">
                        {isLoading && <div className="loader table-loader" ></div>}
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <Form.Label>Find URL</Form.Label>
                                        <Form.Control type="text" value={oldURL} onChange={(e)=>setOldURL(e.target.value)} className="my-2" required /><Form.Control.Feedback type="invalid">
                                            OLD URL Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>Replace URL</Form.Label>
                                        <Form.Control type="text" value={newURL} onChange={(e)=>setNewURL(e.target.value)} className="my-2" required /><Form.Control.Feedback type="invalid">
                                            NEW URL Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                   
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" type="submit" >Save</Button>
                                <Button variant='secondary' className="btn btn-secondary">Cancel</Button>
                            </Card.Footer>
                        </Card>
                    </Form>
                </div>
            </Layout>
    )
}

export default ChangeUrl