import React from 'react';
import Layout from '../layout/Layout';
import { Card, Col, Row } from 'react-bootstrap';

const Home = () => {
    return (
        <Layout sidebar={true}>
            <div className="vi-dashboard">
                <Row>
                    <Col xs={12} className='text-center'>
                        <Card>
                            <Card.Body>
                                <h4 className='mb-0 fw-600'>Welcome to <span className='text-primary'>Affiliation</span> Tracker</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Home