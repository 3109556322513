import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../layout/Layout'
import { Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { SelectPicker } from 'rsuite'
import Fancybox from '../../Component/FancyBox'
import { deleteLanguageImage, getAdType, getAllLanguageImage, saveImages } from '../../service/apis'
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from 'react-toastify'
import InputGroupText from 'react-bootstrap/esm/InputGroupText'
import { useFormik } from 'formik'
import { all } from 'axios'
import { DotLottiePlayer } from '@dotlottie/react-player';
import { AuthContext } from '../../AppContext'

const AddImageForm = () => {
    const { userPermission } = useContext(AuthContext)
    const [updateData, setUpdateData] = useState([])
    const [allImages, setAllImages] = useState([])
    const [allAdType, setAllAdType] = useState([])
    const [filter, setFilter] = useState("")
    const [isEditId, setEditId] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const [openModalValue, setOpenModalValue] = useState({
        backgroundImage: { en: "", gu: "", hi: "", ml: "", mr: "", pa: "", ta: "", te: "", or: "", rj: "", kn: "", bn: "" },
        buttonText: { en: "", gu: "", hi: "", ml: "", mr: "", pa: "", ta: "", te: "", or: "", rj: "", kn: "", bn: "" },
        headlineText: { en: "", gu: "", hi: "", ml: "", mr: "", pa: "", ta: "", te: "", or: "", rj: "", kn: "", bn: "" },
        pragraphaText: { en: "", gu: "", hi: "", ml: "", mr: "", pa: "", ta: "", te: "", or: "", rj: "", kn: "", bn: "" }
    });
    const [openModal, setOpenModal] = useState({
        backgroundImage: false,
        buttonText: false,
        headlineText: false,
        pragraphaText: false
    });
    // const [modelData,setModalData] = useState()
    const [isNeedToAddAllLanguage, setIsNeedToAddAllLanguage] = useState({
        backgroundImage: false,
        buttonText: false,
        headlineText: false,
        pragraphaText: false
    });
    /** toggle for add all language button  */
    const handleShowButton = (value, key) => {
        setIsNeedToAddAllLanguage({ ...isNeedToAddAllLanguage, [key]: value })
    };

    /** open modal and show data in input field when click edit button */
    const handleClose = (key) => {
        setOpenModal({ [key]: false })
    };
    const handleShow = (key) => {
        setOpenModal({ [key]: true })
    };

    useEffect(() => {
        fetchAllLanguageImage();
        fetchAdType();
    }, [])



    /**change modal values */

    const handleBachgroundImage = (value, key) => {
        setOpenModalValue({ ...openModalValue, backgroundImage: { ...openModalValue.backgroundImage, [key]: value } });
    }
    const handleButtonText = (value, key) => {
        setOpenModalValue({ ...openModalValue, buttonText: { ...openModalValue.buttonText, [key]: value } });
    }
    const handleHeadlineText = (value, key) => {
        setOpenModalValue({ ...openModalValue, headlineText: { ...openModalValue.headlineText, [key]: value } });
    }
    const handlePragraphaText = (value, key) => {
        setOpenModalValue({ ...openModalValue, pragraphaText: { ...openModalValue.pragraphaText, [key]: value } });
    }

    /** Save ButtonText Modal */
    const handleBackgroundImageSave = () => {
        let images = [...allImages];
        Object.keys(openModalValue?.backgroundImage).map((key => {
            images[isEditId][key] = openModalValue.backgroundImage[key]
        }))
        setAllImages(images);
        setOpenModal({ backgroundImage: false });
    };
    const handleButtonTextSave = () => {
        let images = [...allImages];
        images[isEditId].button_text = JSON.stringify(openModalValue.buttonText)
        setAllImages(images);
        setOpenModal({ buttonText: false })
    }
    const handleHeadlineTextSave = () => {
        let images = [...allImages];
        images[isEditId].headline_text = JSON.stringify(openModalValue.headlineText)
        setAllImages(images);
        setOpenModal({ headlineText: false })
    }
    const handlePragraphaTextSave = () => {
        let images = [...allImages];
        images[isEditId].paragraph_text = JSON.stringify(openModalValue.pragraphaText)
        setAllImages(images);
        setOpenModal({ pragraphaText: false })
    }

    /**Get addType dropdown */
    const fetchAdType = async () => {
        setIsLoading(true);
        try {
            const response = await getAdType()
            setAllAdType(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const fetchAllLanguageImage = async () => {
        setIsLoading(true);
        try {
            const response = await getAllLanguageImage()
            setAllImages(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }



    // Save recoard
    const handleSave = (event, index) => {

        event.preventDefault();
        const form = event.currentTarget;
        setValidated(true)
        if (form.checkValidity() === false || allImages[index]?.adtype_id <= 0) {
            event.stopPropagation();
            setValidated(true)
        } else {
            try {
                setIsLoading(true);
                const formData = new FormData();
                Object?.keys(allImages[index])?.map((item) => {
                    formData.append(item, allImages[index][item] ?? '')
                })
                saveImages(formData).then((item) => {
                    toast?.success(item?.data?.message); fetchAllLanguageImage();
                    setIsLoading(false);
                })
            } catch (error) {
                console.log(error)
            } finally {
                setValidated(false)
                setEditId(-1)
                document.getElementById("myForm").reset()
            }
        }

    }

    /**change  values */

    const handleChange = (e, index, property, en) => {
        let images = [...allImages];
        if (en) {
            let parsedProperty = JSON.parse(images[index][property]);
            parsedProperty[en] = e;
            images[index][property] = JSON.stringify(parsedProperty)
            setAllImages(images);
        } else {
            images[index][property] = e;

            // Update the state with the modified images array
            setAllImages(images);
        }
    };

    // set Dropdown value 
    let adTypes = []
    allAdType?.length > 0 && allAdType?.map((item) => adTypes.push({ label: item.type, value: item?.id }))
    // set Dropdown value 
    let searchAdTypes = [{ value: "", label: "All" }]
    allAdType?.length > 0 && allAdType?.map((item) => searchAdTypes.push({ label: item.type, value: item?.id }))

    // Add new images 

    const handleNewAdd = () => {
        setFilter("")
        setEditId(0)
        setAllImages((prevImages) => [
            {
                adtype_id: 0,
                en: "",
                hi: "",
                mr: "",
                gu: "",
                kn: "",
                ta: "",
                te: "",
                bn: "",
                ml: "",
                or: "",
                pa: "",
                rj: "",
                background_color: "",
                background_border_color: "",
                button_color: "",
                button_text_color: "",
                button_border_color: "",
                button_text: "{     \"en\": \"\",     \"gu\": \"\",     \"hi\": \"\",     \"ml\": \"\",     \"mr\": \"\",     \"pa\": \"\",     \"ta\": \"\",     \"te\": \"\",     \"or\": \"\",     \"rj\": \"\",     \"kn\": \"\",     \"bn\": \"\" }",
                image: "",
                headline_text: "{     \"en\": \"\",     \"gu\": \"\",     \"hi\": \"\",     \"ml\": \"\",     \"mr\": \"\",     \"pa\": \"\",     \"ta\": \"\",     \"te\": \"\",     \"or\": \"\",     \"rj\": \"\",     \"kn\": \"\",     \"bn\": \"\" }",
                headline_text_color: "",
                paragraph_text: "{     \"en\": \"\",     \"gu\": \"\",     \"hi\": \"\",     \"ml\": \"\",     \"mr\": \"\",     \"pa\": \"\",     \"ta\": \"\",     \"te\": \"\",     \"or\": \"\",     \"rj\": \"\",     \"kn\": \"\",     \"bn\": \"\" }",
                paragraph_color: "",
                text_color: "",
            },
            ...prevImages,
        ]);
    }

    // Delete

    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id, index) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    setAllImages(allImages?.length > 0 && allImages?.filter((item, i) => i !== index))
                    id && deleteLanguageImage(id).then((item) => toast.error(item?.data?.message))
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    return (
        <>
            <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading backbtn">
                    <h3><Link to="/affiliation" className='btn btn-transparent btn-icon me-2'><i className='bx bx-chevron-left'></i></Link>Affiliation Add Image</h3>
                    <div className="page-heading-right">
                        <SelectPicker
                            data={searchAdTypes}
                            cleanable={false}
                            className='my-2 wv-250'
                            value={filter}
                            onChange={(e) => setFilter(e)}
                            placeholder="Select Ad Type"
                            placement="bottomEnd"
                        />
                        {userPermission["Affilation-Image"]?.add == 1 && <Button variant="primary ms-3 my-1" type="button"
                            onClick={handleNewAdd}
                        >Add New</Button>}
                    </div>
                </div>
                <div className="page-content">
                    <Row className="g-4">
                        {/* {allLanguage?.length > 0 && allLanguage?.map((language, index) => {
                            return ( */}
                        {allImages?.length > 0 && allImages?.map((item, index) => {
                            return ((item.adtype_id == filter || filter == "" || !item.adtype_id) && <Col sm={12} key={index}>
                                <Card>
                                    <Form id='myForm' noValidate validated={validated} onSubmit={(e) => handleSave(e, index)}>
                                        <Card.Header className="border-bottom">
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <h4 className="m-0 fw-bold">
                                                    {adTypes?.find((el) => el?.value == item.adtype_id)?.label &&
                                                        adTypes?.find((el) => el?.value == item.adtype_id)?.label?.charAt(0).toUpperCase() + adTypes?.find((el) => el?.value == item.adtype_id)?.label?.slice(1)?.toLowerCase()}
                                                </h4>
                                                <div>
                                                    {isEditId == index && <Button variant="primary px-3"
                                                        type='submit'
                                                        size="sm">Save</Button>}
                                                    {isEditId != index && userPermission["Affilation-Image"]?.edit == 1 && <Button variant="info" size="sm" onClick={() => setEditId(index)}
                                                        className="btn-icon-lg  ms-3" ><i className='bx bx-pencil'></i></Button>}
                                                    {userPermission["Affilation-Image"]?.delete == 1 && <Button variant="danger" size="sm" onClick={() => handleDelete(item.id, index)}
                                                        className="btn-icon-lg ms-3"><i className='bx bx-x'></i></Button>}
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col md={3}>
                                                    <Form.Label>Ad Type</Form.Label>
                                                    <SelectPicker
                                                        data={adTypes}
                                                        cleanable={false}
                                                        className='my-2'
                                                        value={item?.adtype_id}
                                                        disabled={isEditId != index}
                                                        onChange={(e) => handleChange(e, index, "adtype_id")}
                                                        block
                                                        placeholder="Select Ad Type"
                                                        placement="bottomEnd"
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Background Color</Form.Label>
                                                    <Form.Control name="bgimage" disabled={isEditId != index} className='my-2' type="text" value={item?.background_color}
                                                        onChange={(e) => handleChange(e.target.value, index, "background_color")}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Background Border Color</Form.Label>
                                                    <Form.Control name="bgimage" disabled={isEditId != index} className='my-2' type="text" value={item?.background_border_color}
                                                        onChange={(e) => handleChange(e.target.value, index, "background_border_color")}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Background Image</Form.Label>
                                                    <InputGroup className="my-2">
                                                        <Form.Control name="bgimage" type="file" disabled={isEditId != index} onChange={(e) => handleChange(e.target.files[0], index, "en")} />
                                                        {/* <InputGroup.Text><Form.Check type="checkbox" disabled={isEditId != index} checked={item?.backgroundImage} onChange={(e) => handleChange(e.target.checked, index, "backgroundImage")} label="All" /></InputGroup.Text>
                                                        {item?.backgroundImage &&  */}
                                                        <Fancybox>
                                                            <a href={item?.en} data-fancybox="gallery">
                                                                <img src={item?.en} className="hv-40 rounded-3" alt="" />
                                                            </a>
                                                        </Fancybox>
                                                        <Button variant='primary' disabled={isEditId != index} onClick={() => handleShow("backgroundImage")}>+</Button>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Button Background Color</Form.Label>
                                                    <Form.Control name="bgimage" className='my-2' type="text" disabled={isEditId != index} value={item?.button_color}
                                                        onChange={(e) => handleChange(e.target.value, index, "button_color")}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Button Border Color</Form.Label>
                                                    <Form.Control name="bgimage" className='my-2' type="text" disabled={isEditId != index} value={item?.button_border_color}
                                                        onChange={(e) => handleChange(e.target.value, index, "button_border_color")}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Button Text Color</Form.Label>
                                                    <Form.Control name="bgimage" className='my-2' type="text" disabled={isEditId != index} value={item?.button_text_color}
                                                        onChange={(e) => handleChange(e.target.value, index, "button_text_color")}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Button Text</Form.Label>
                                                    <InputGroup className="my-2">
                                                        <Form.Control name="bgimage" type="text" disabled={isEditId != index} value={(item?.button_text && item?.button_text != "") ? JSON.parse(item?.button_text).en : ""} onChange={(e) => handleChange(e.target.value, index, "button_text", "en")} />
                                                        {/* <InputGroup.Text> */}
                                                        {/* <Form.Check disabled={isEditId != index} type="checkbox" checked={item?.buttonText} onChange={(e) => handleChange(e.target.checked, index, "buttonText")} label="All" /></InputGroup.Text> */}
                                                        {/* {item?.buttonText &&  */}
                                                        <Button variant='primary' disabled={isEditId != index} onClick={() => {
                                                            setOpenModalValue({ ...openModalValue, buttonText: (item?.button_text && item?.button_text != "") ? JSON.parse(item?.button_text) : "" });
                                                            handleShow("buttonText")
                                                        }}>+</Button>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Heading Text</Form.Label>
                                                    <InputGroup className="my-2">
                                                        <Form.Control name="bgimage" type="text" disabled={isEditId != index} value={(item?.headline_text && item?.headline_text != "") ? JSON.parse(item?.headline_text).en : ""} onChange={(e) => handleChange(e.target.value, index, "headline_text", "en")} />
                                                        {/* <InputGroup.Text><Form.Check type="checkbox" disabled={isEditId != index} checked={item?.headlineText} onChange={(e) => handleChange(e.target.checked, index, "headlineText")} label="All" /></InputGroup.Text>
                                                        {item.headlineText && */}
                                                        <Button variant='primary' disabled={isEditId != index} onClick={() => {
                                                            setOpenModalValue({ ...openModalValue, headlineText: (item?.headline_text && item?.headline_text != "") ? JSON.parse(item?.headline_text) : "" });
                                                            handleShow("headlineText")
                                                        }}>+</Button>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Heading Text Color</Form.Label>
                                                    <Form.Control name="bgimage" className='my-2' disabled={isEditId != index} type="text" value={item?.headline_text_color}
                                                        onChange={(e) => handleChange(e.target.value, index, "headline_text_color")}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Pragrapha Text</Form.Label>
                                                    <InputGroup className="my-2">
                                                        <Form.Control name="bgimage" type="text" disabled={isEditId != index} value={(item?.paragraph_text && item?.paragraph_text != "") ? JSON.parse(item?.paragraph_text).en : ""} onChange={(e) => handleChange(e.target.value, index, "paragraph_text", "en")} />
                                                        {/* <InputGroup.Text><Form.Check type="checkbox" disabled={isEditId != index} checked={item?.pragraphaText} onChange={(e) => handleChange(e.target.checked, index, "pragraphaText")} label="All" /></InputGroup.Text>
                                                        {item.pragraphaText &&  */}
                                                        <Button variant='primary' disabled={isEditId != index} onClick={() => {
                                                            setOpenModalValue({ ...openModalValue, pragraphaText: (item?.paragraph_text && item?.paragraph_text != "") ? JSON.parse(item?.paragraph_text) : "" });
                                                            handleShow("pragraphaText")
                                                        }}>+</Button>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Pragrapha Text Color</Form.Label>
                                                    <Form.Control name="bgimage" className='my-2' type="text" disabled={isEditId != index} value={item?.paragraph_color}
                                                        onChange={(e) => handleChange(e.target.value, index, "paragraph_color")}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Image-1</Form.Label>
                                                    <InputGroup className="my-2">
                                                        <Form.Control name="bgimage" onChange={(e) => handleChange(e.target.files[0], index, "image1")} disabled={isEditId != index} type="file" />
                                                        {item?.image1 && typeof item.image1 === 'string' && (
                                                            item.image1.split('.').pop() === 'json' ? (
                                                                <DotLottiePlayer src={item.image1} autoplay loop className="lottie-player"></DotLottiePlayer>
                                                            ) : (
                                                                <Fancybox>
                                                                    <a href={item.image1} data-fancybox="gallery">
                                                                        <img src={item.image1} className="hv-40 rounded-3" alt="" />
                                                                    </a>
                                                                </Fancybox>
                                                            )
                                                        )}
                                                        {
                                                            (item?.image1 && typeof item.image1 === 'string' && ['mov', 'mp4'].includes(item.image1.split('.').pop())) &&
                                                            <video width="40" height="40" controls>
                                                                <source src="https://rtoapplication.sgp1.cdn.digitaloceanspaces.com/local/languageImage/1708945496220.mov" type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        }
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Image-2</Form.Label>
                                                    <InputGroup className="my-2">
                                                        <Form.Control name="bgimage" onChange={(e) => handleChange(e.target.files[0], index, "image2")} disabled={isEditId != index} type="file" />
                                                        <Fancybox>
                                                            <a href={item?.image2} data-fancybox="gallery">
                                                                <img src={item?.image2} className="hv-40 rounded-3" alt="" />
                                                            </a>
                                                        </Fancybox>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>video</Form.Label>
                                                    <InputGroup className="my-2">
                                                        <Form.Control name="bgimage" onChange={(e) => handleChange(e.target.files[0], index, "video")} accept='.mp4' disabled={isEditId != index} type="file" />
                                                        <Fancybox>
                                                            <a href={item?.video} data-fancybox="gallery">
                                                                <img src={item?.video} className="hv-40 rounded-3" alt="" />
                                                            </a>
                                                        </Fancybox>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Form>
                                </Card>
                            </Col>)
                        })}
                    </Row>
                </div>
                <Modal show={openModal.backgroundImage} onHide={() => handleClose("backgroundImage")} size='xl' scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>Background Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Label>English</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "en")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.en} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.en} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Gujarati</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "gu")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.gu} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.gu} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Hindi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "hi")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.hi} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.hi} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Kannada</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "kn")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.kn} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.kn} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Malayalm</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "ml")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.ml} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.ml} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Marathi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "mr")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.mr} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.mr} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Odia</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "or")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.or} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.or} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Punjabi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "pa")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.pa} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.pa} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Tamil</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "ta")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.ta} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.ta} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Telugu</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "te")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.te} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.te} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Rajasthani</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "rj")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.rj} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.rj} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Bengali</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" type="file" onChange={(e) => handleBachgroundImage(e.target.files[0], "bn")} />
                                    <Fancybox>
                                        <a href={allImages[isEditId]?.bn} data-fancybox="gallery">
                                            <img src={allImages[isEditId]?.bn} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose("backgroundImage")}>
                            Close
                        </Button>
                        <Button variant="primary ms-2" onClick={handleBackgroundImageSave}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={openModal.buttonText} onHide={() => handleClose("buttonText")} size='xl' scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>Button Text</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Label>English</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.en} onChange={(e) => handleButtonText(e.target.value, "en")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Gujarati</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.gu} onChange={(e) => handleButtonText(e.target.value, "gu")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Hindi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.hi} onChange={(e) => handleButtonText(e.target.value, "hi")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Kannada</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.kn} onChange={(e) => handleButtonText(e.target.value, "kn")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Malayalm</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.ml} onChange={(e) => handleButtonText(e.target.value, "ml")} type="text" />

                                </InputGroup>
                            </Col>

                            <Col md={4}>
                                <Form.Label>Marathi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.mr} onChange={(e) => handleButtonText(e.target.value, "mr")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Odia</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.or} onChange={(e) => handleButtonText(e.target.value, "or")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Punjabi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.pa} onChange={(e) => handleButtonText(e.target.value, "pa")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Tamil</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.ta} onChange={(e) => handleButtonText(e.target.value, "ta")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Telugu</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.te} onChange={(e) => handleButtonText(e.target.value, "te")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Rajasthani</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.rj} onChange={(e) => handleButtonText(e.target.value, "rj")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Bengali</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.buttonText.bn} onChange={(e) => handleButtonText(e.target.value, "bn")} type="text" />

                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose("buttonText")}>
                            Close
                        </Button>
                        <Button variant="primary ms-2" onClick={handleButtonTextSave}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={openModal.headlineText} onHide={() => handleClose("headlineText")} size='xl' scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>Headline Text</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Label>English</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.en} onChange={(e) => handleHeadlineText(e.target.value, "en")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Gujarati</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.gu} onChange={(e) => handleHeadlineText(e.target.value, "gu")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Hindi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.hi} onChange={(e) => handleHeadlineText(e.target.value, "hi")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Kannada</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.kn} onChange={(e) => handleHeadlineText(e.target.value, "kn")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Malayalm</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.ml} onChange={(e) => handleHeadlineText(e.target.value, "ml")} type="text" />

                                </InputGroup>
                            </Col>

                            <Col md={4}>
                                <Form.Label>Marathi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.mr} onChange={(e) => handleHeadlineText(e.target.value, "mr")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Odia</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.or} onChange={(e) => handleHeadlineText(e.target.value, "or")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Punjabi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.pa} onChange={(e) => handleHeadlineText(e.target.value, "pa")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Tamil</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.ta} onChange={(e) => handleHeadlineText(e.target.value, "ta")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Telugu</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.te} onChange={(e) => handleHeadlineText(e.target.value, "te")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Rajasthani</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.rj} onChange={(e) => handleHeadlineText(e.target.value, "rj")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Bengali</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.headlineText.bn} onChange={(e) => handleHeadlineText(e.target.value, "bn")} type="text" />

                                </InputGroup>
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose("headlineText")}>
                            Close
                        </Button>
                        <Button variant="primary ms-2" onClick={handleHeadlineTextSave}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={openModal.pragraphaText} onHide={() => handleClose("pragraphaText")} size='xl' scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>Pragrapha Text</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Label>English</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.en} onChange={(e) => handlePragraphaText(e.target.value, "en")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Gujarati</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.gu} onChange={(e) => handlePragraphaText(e.target.value, "gu")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Hindi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.hi} onChange={(e) => handlePragraphaText(e.target.value, "hi")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Kannada</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.kn} onChange={(e) => handlePragraphaText(e.target.value, "kn")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Malayalm</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.ml} onChange={(e) => handlePragraphaText(e.target.value, "ml")} type="text" />

                                </InputGroup>
                            </Col>

                            <Col md={4}>
                                <Form.Label>Marathi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.mr} onChange={(e) => handlePragraphaText(e.target.value, "mr")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Odia</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.or} onChange={(e) => handlePragraphaText(e.target.value, "or")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Punjabi</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.pa} onChange={(e) => handlePragraphaText(e.target.value, "pa")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Tamil</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.ta} onChange={(e) => handlePragraphaText(e.target.value, "ta")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Telugu</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.te} onChange={(e) => handlePragraphaText(e.target.value, "te")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Rajasthani</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.rj} onChange={(e) => handlePragraphaText(e.target.value, "rj")} type="text" />

                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Bengali</Form.Label>
                                <InputGroup className="my-2">
                                    <Form.Control name="bgimage" value={openModalValue.pragraphaText.bn} onChange={(e) => handlePragraphaText(e.target.value, "bn")} type="text" />

                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose("pragraphaText")}>
                            Close
                        </Button>
                        <Button variant="primary ms-2" onClick={handlePragraphaTextSave}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Layout>
        </>
    )
}

export default AddImageForm