import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Form, Card, Row, Col, Button, Breadcrumb, InputGroup, Image } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addAffilationCity, viewAffilationCity } from "../../service/apis";
import { toast } from "react-toastify";
import Switch from 'react-switch'

const AffiliationCityAdd = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [affilationCityPincide, setAffilationCityPincode] = useState([])
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [image, setImage] = useState("")
    const [is_popular, setIs_popular] = useState("")
    const [allAffilationCityData, setAllAffilationCityData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setvalidated] = useState(false)


    useEffect(() => {
        fetchAffilationCity(params)
        // fetchAffilationCityDropdown()
    }, [params])
    // Apis 

    const fetchAffilationCity = async (params) => {
        setIsLoading(true);
        try {
            const response = await viewAffilationCity(params.id)
            setAffilationCityPincode(response?.data?.data?.pin_code_data || [])
            setState(response?.data?.data?.state)
            setCity(response?.data?.data?.city)
            setImage(response?.data?.data?.image)
            setIs_popular(response?.data?.data?.is_popular)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const handleAddPincode = () => {
        setvalidated(false)
        setAffilationCityPincode([...affilationCityPincide, { pincode: "", area: "" }])
    }


    // onChange

    const handleChange = (e, index, item) => {
        const updatedData = [...affilationCityPincide];
        if (item == "pincode") {
            const input = e.target.value;
            const regex = /^[1-9]\d*$/;
            if (regex.test(input) || input === "") {
                updatedData[index][item] = e.target.value
            }

        } else {

            updatedData[index][item] = e.target.value
        }
        setAffilationCityPincode(updatedData);
    }
    //Delete
    const handleDelete = (index) => {

        affilationCityPincide?.length > 0 && setAffilationCityPincode(affilationCityPincide?.filter((el, i) => i != index))
    }

    const isPincode = () => {
        return affilationCityPincide?.some((item) => { return item?.pincode.length != 6 })
    }

    //save

    const onsubmit = (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            event.stopPropagation();
            try {
                if ((city && state)) {
                    setIsLoading(true);
                    const formData = new FormData()
                    formData.append('city', city)
                    formData.append('state', state);
                    image && formData.append('image', image);
                    formData.append('is_popular', is_popular == undefined ? 0 : is_popular);
                    params.id && formData.append('id', params.id);
                    formData.append('pin_code_data', JSON.stringify(affilationCityPincide));
                    addAffilationCity(formData).then(() => {
                        navigate("/affiliation-city");
                        toast.success(` ${params.id ? "city Update successfully" : "city Save successfully"}`)
                    }
                    )
                }
            } catch (error) {

                console.log(error)
            } finally {
                setIsLoading(false);
            }
        } else
            setvalidated(true)
    }
    // const onsubmit = () => {
    //     try {
    //         if ((city && state && !isPincode())) {
    //             setIsLoading(true);
    //             const formData = new FormData()
    //             formData.append('city', city)
    //             formData.append('state', state);
    //             params.id && formData.append('id', params.id);
    //             formData.append('pin_code_data', JSON.stringify(affilationCityPincide));
    //             addAffilationCity(formData).then(() => {
    //                 navigate("/affiliation-city");
    //                 toast.success(` ${params.id ? "city Update successfully" : "city Save successfully"}`)
    //             }
    //             )
    //         } else {
    //             setvalidated(true)
    //         }
    //     } catch (error) {

    //     } finally {
    //         setIsLoading(false);
    //     }
    // }

    return (
        <>
            <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading">
                    <h3>Affiliation City {params.id ? "Edit" : "Add"}</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/affiliation-city">Affiliation City</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Affiliation City {params.id ? "Edit" : "Add"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Form noValidate validated={validated} onSubmit={onsubmit}>
                        <Card className="radiustop-0">
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <Form.Label>State</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setState(e.target.value)} value={state} className="my-2" required /><Form.Control.Feedback type="invalid">
                                            State Key Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setCity(e.target.value)} value={city} className="my-2" required /><Form.Control.Feedback type="invalid">
                                            City Key Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    {/* New Addd */}
                                    <Col md={6}>
                                        <Form.Label>Image</Form.Label>
                                        <Form.Control type="file" name="image" onChange={(e) => setImage(e.target.files[0])} className="my-2" />
                                        {
                                            params.id &&
                                            <InputGroup className="my-2">
                                                {
                                                    image &&
                                                    <Image
                                                        src={image}
                                                        className="hv-30 rounded-3"
                                                    ></Image>
                                                }
                                            </InputGroup>
                                        }
                                        {/* <Form.Control.Feedback type="invalid">
                                            City Key Field Is Require
                                        </Form.Control.Feedback> */}
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label htmlFor="is_popular" className="d-block">
                                            Is Popular
                                        </Form.Label>
                                        <div className="d-flex align-items-center my-2">
                                            <Switch
                                                onChange={(e) => setIs_popular(pre => e == true ? 1 : 0)}
                                                name="status"
                                                checked={is_popular === 1 ? true : false}
                                                offColor="#FF4560"
                                                onColor="#00C24E"
                                                height={24}
                                                width={40}
                                                className="react-switch react-switch-small"
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                            />
                                        </div>
                                    </Col>
                                    {/* End  ------ */}
                                    <Col md={12} className="d-flex align-items-center justify-content-between">
                                        <Form.Label>Add Pincode</Form.Label>
                                        <Button variant="primary my-2" onClick={handleAddPincode}><i className="bx bx-plus"></i></Button>
                                    </Col>
                                    <Col md={12}>
                                        {affilationCityPincide?.length > 0 && affilationCityPincide?.map((item, index) => {
                                            return (<Row key={index}>
                                                <Col md={6}>
                                                    <Form.Control type="text" placeholder="Pincode" className="my-2" pattern="^[1-9]\d{5}$" maxLength={6} onChange={(e) => handleChange(e, index, "pincode")} value={item?.pincode} required />
                                                    {/* {item?.pincode?.length != 6 && <div className="text-danger">Enter Valid Pincode</div>} */}
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Valid Pincode
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={5}>
                                                    <Form.Control type="text" placeholder="Pincode Area" className="my-2" onChange={(e) => handleChange(e, index, "area")} value={item?.area} />
                                                </Col>
                                                <Col md={1} className="align-self-end text-end">
                                                    <Button variant="danger" className="my-2" onClick={() => handleDelete(index)}><i className="bx bx-x"></i></Button>
                                                </Col>
                                            </Row>)
                                        })}
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" type="submit" >Save</Button>
                                <Link to='/affiliation-city' className="btn btn-secondary">Cancel</Link>
                            </Card.Footer>
                        </Card>
                    </Form>
                </div>
            </Layout>
        </>
    )
}

export default AffiliationCityAdd;