import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../App";
import Layout from "../../layout/Layout";
import Cookies from "js-cookie";
import { SelectPicker } from "rsuite";
import $ from "jquery";
import * as Yup from 'yup';
import { useFormik } from "formik";

const UserEdit = () => {
  const token = Cookies.get("fmljwt");
  const params = useParams()
  const navigate = useNavigate();
  const [isPassword, setPassword] = useState(false)
  const [platformHook, setplatformHook] = useState([])
  const [addData, setAddData] = useState()

const validationSchema = Yup.object({
  email: Yup.string().email('Enter valid email').required('Email is required'),
  role: Yup.string().required('Role is required'),
  name: Yup.string().required('Name is required'),
  password:isPassword? Yup.string().required('Password is required'):"",
  confirmPassword:isPassword? Yup.string()
  .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'):""
});


  const getData = async (id) => {
    const res = await API.post(`/permission/viwe-user/${id}`, {}, { headers: { authorization: `${token}` } });
    setAddData(res.data.data)
  }

  const roleData = async () => {
    const resut = await API.post("/permission/roles", {}, { headers: { authorization: `${token}` } });
    let platform_array = []
    platform_array.push({ label: "Select Role", value: "" })
    resut.data.data.map((val, index) => {
      platform_array.push({ label: val.name, value: val.id })
    })
    setplatformHook(platform_array)
  };


  const formik = useFormik({
    initialValues: {
      name: addData?.name,
      password: "",
      email: addData?.email,
      confirmPassword: "",
      role: addData?.role?.RoleName?.id
    },
    validationSchema: validationSchema,
    enableReinitialize:true,
    onSubmit: async (values) => {
      const Form = new FormData()
      Form.append('name', values.name.trim());
      Form.append('email', values.email.trim());
      (values.password&&values.confirmPassword)&&Form.append('password', values.confirmPassword.trim());
      Form.append('role_id', values.role);
      const res = await API.post(`/permission/update-user/${params.id}`, Form, { headers: { authorization: `${token}` } })
      toast.success("User Updated Successfully")
      navigate(`/user/view/${params.id}`)
    },
  });

  useEffect(() => {
    getData(params.id)
    roleData()
  }, [])

  return (
    <>
      <Layout sidebar={true}>
        <div className="page-heading">
          <h3>Permission Edit</h3>
          <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
            <Breadcrumb.Item>
              <Link to="/home">
                <i className="bx bx-home-alt me-2 fs-5"></i> Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/user">List Permission</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Permission Edit</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="page-content">
          <Form onSubmit={formik.handleSubmit}>
              <Card className="mb-4 radiustop-0">
                <Card.Body>
                  <Row>
                    <Col md={3}>
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <Form.Control type="text" className="my-2" name="name" value={formik.values.name} onChange={formik.handleChange} />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                      ) : null}
                    </Col>

                    <Col md={3}>
                      <Form.Label htmlFor="email">Email</Form.Label>
                      <Form.Control type="text" className="my-2" name="email" value={formik.values.email} onChange={formik.handleChange} />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <Form.Label htmlFor="Role">Role</Form.Label>
                      <SelectPicker
                        cleanable={false}
                        data={platformHook}
                        name="platform"
                        value={formik.values.role}
                        className={`my-2`}
                        block
                        placeholder="Select platform"
                        onChange={(e) => formik.setFieldValue("role", e)}
                        onEnter={() => { $(".rs_UserAdd68").addClass("arrUpDown") }}
                        onExit={() => { $(".rs_UserAdd68").removeClass("arrUpDown") }}
                      />
                      {formik.touched.role && formik.errors.role ? (
                        <div className="text-danger">{formik.errors.role}</div>
                      ) : null}
                    </Col>
                    <Col md={3} className="d-flex align-self-end">
                      <Button variant="info" onClick={()=>setPassword(!isPassword)}className="my-2">Change Password</Button>
                    </Col>
                  </Row>
                  <Row>
                    {isPassword&&<> <Col md={3}>
                      <Form.Label htmlFor="adslink">New Password</Form.Label>
                      <Form.Control type="text" className="my-2" name="password" value={formik.values.password} onChange={formik.handleChange} />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger">{formik.errors.password}</div>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <Form.Label htmlFor="adslink">Confirm Password</Form.Label>
                      <Form.Control
                        type="text"
                        className="my-2"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}

                        onChange={formik.handleChange}
                      />
                      {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <div className="text-danger">{formik.errors.confirmPassword}</div>
                      ) : null}
                    </Col></>}
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" type="submit">
                    Save
                  </Button>
                  <Link to={`/user/view/${params.id}`}>
                    <Button variant="secondary">Cancel</Button>
                  </Link>
                </Card.Footer>
              </Card>
          </Form>
        </div>
      </Layout>
    </>
  )
}
export default UserEdit