import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Pagination from "rc-pagination";
import Layout from "../../layout/Layout";
import { Link } from "react-router-dom";
import { API } from "../../App";
import { object } from "yup";
import Swal from "sweetalert2";
import $ from "jquery";
import { downloadCSV, getTrackerCity, getTrackerCityPincode, getTrackerDropdown, getTrackerServices, getTrakerData, GetTrackerUtmTerm, deleteTrakerData } from "../../service/trackerService";
import copy from 'clipboard-copy';
import { toast } from "react-toastify";
import { AuthContext } from "../../AppContext";
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from "moment";


const AffiliationTracker = () => {
    const URL = process.env.REACT_APP_MAPPING_URL
    const { userPermission } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(true);
    const [trackershow, setTrackerShow] = useState(false);
    const [trakerData, setTrakerData] = useState([]);
    const [isFileDownloading, setIsFileDownloading] = useState(false)
    const [trakerDropdown, setTrakerDropdown] = useState({
        service_provider: [],
        services: [],
        cities: [],
        utm_terms: [],
        fields: [],
        pincode: []
    });
    const [selectedFilter, setSelectedFilter] = useState({
        service_provider: "",
        services: "",
        cities: "",
        utm_terms: "",
        pincode: ""
    });
    const [selectedState, setSelectedState] = useState("");
    const [limit, setLimit] = useState("100");
    const [current, setCurrent] = useState(1);
    const [mappingList, setMappingList] = useState(1);
    const [trackerExportshow, setTrackerExportShow] = useState(false);
    const [selectedCsvFields, setSelectedCsvFields] = useState([]);
    const [dateRanges, setdateRanges] = useState({
        start: moment().subtract(6, 'days'),
        end: moment(),
    });
    const { start, end } = dateRanges;
    const handleCallback = (start, end) => {
        setCurrent(1);
        setdateRanges({ start, end });
    };

    const label = start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY');

    const handleTrackerClose = () => setTrackerShow(false);
    const handleTrackerShow = () => setTrackerShow(true);

    const handleTrackerExportClose = () => { setTrackerExportShow(false); setSelectedCsvFields([]) };
    const handleTrackerExportShow = () => setTrackerExportShow(true);

    const limits = ['100', "200", '500', "1000"].map(
        item => ({ label: item, value: item })
    );
    const transformData = (data, label) => {
        const allOption = [{ value: "", label: label }];
        const transformedData = data?.length > 0 ? data?.map(item => ({
            value: item.name,
            label: item.name
        })) : [];
        return [...allOption, ...transformedData];
    };
    const transformUtmTerm = (data, label) => {
        const allOption = [{ value: "", label: label }];
        const transformedData = data
        ?.filter(item => item?.count != 0)
        ?.map(item => ({
            value: item.id,
            label: item.name +" ("+item?.count+")"
        })) || [];
        return [...allOption, ...transformedData];
    };

    /** Fetch Tracker Dropdown */
    const fetchTrackerDropdown = async () => {
        try {
            const response = await getTrackerDropdown();
            const transformedData = response?.data?.cities.map(item => ({
                value: item?.id,
                label: item?.city + ", " + item?.state
            }));

            const allOption = [{ value: "", label: "All Service Cities" }]
            if (response?.data?.service_provider?.length > 0) {
                setTrakerDropdown(prevState => ({
                    ...prevState,
                    service_provider: response?.data?.service_provider?.length > 0 && transformData(response?.data?.service_provider, "All Service Provider"),
                    fields: response?.data?.feilds,
                    cities: [...allOption, ...transformedData],
                }))
            };
        } catch (error) {
            console.log('error', error);
        }
    };

    const fetchTrackerServices = async (provider_name) => {
        try {
            const formdata = new FormData();
            formdata.append("service_provider", provider_name);
            const response = await getTrackerServices(formdata);
            setTrakerDropdown(prevState => ({
                ...prevState,
                services: transformData(response?.data?.services, "All Services"),
            }));
        } catch (error) {
            console.log('error', error);
        }
    };

    // const fetchTrackerCity = async () => {
    //     const formdata = new FormData();
    //     // selectedFilter?.service_provider && formdata.append("service_provider", selectedFilter?.service_provider);
    //     // selectedFilter?.services && formdata.append("service", selectedFilter?.services);
    //     try {
    //         const response = await getTrackerCity(formdata);
    //         console.log('response', response)
    //         const allOption = [{ value: "", label: "All Service Cities" }];
    //         // const allOptionUTM = [{ value: "", label: "All UTM Terms" }];
    //         const transformedData = response?.data?.cityList.map(item => ({
    //             value: item?.id,
    //             label: item?.city + ", " + item?.state
    //         }));
    //         // return [...allOption, ...transformedData];
    //         setTrakerDropdown(prevState => ({
    //             ...prevState,
    //             cities: [...allOption, ...transformedData],
    //             // utm_terms: transformUtmTerm(response?.data?.utmTerms, "All UTM Terms"),
    //         }));
    //     } catch (error) {
    //         console.log('error', error);
    //     }
    // };
    const fetchUtmTerm = async () => {
        if (selectedFilter.service_provider || selectedFilter.services) {
            const formdata = new FormData();
            selectedFilter?.service_provider && formdata.append("service_provider", selectedFilter?.service_provider);
            selectedFilter?.services && formdata.append("service", selectedFilter?.services);
            dateRanges?.start && formdata?.append("startDate", start.format('YYYY-MM-DD'))
            dateRanges?.end && formdata?.append("endDate", end.format('YYYY-MM-DD'))
            try {
                const response = await GetTrackerUtmTerm(formdata);
                console.log('response', response)
                setTrakerDropdown(prevState => ({
                    ...prevState,
                    utm_terms: transformUtmTerm(response?.data?.utmTerms, "All UTM Terms"),
                }));
            } catch (error) {
                console.log('error', error);
            }
        }
    };
    const fetchTrackerCityPincode = async () => {
        if (selectedFilter?.cities) {
            const formdata = new FormData();
            selectedFilter?.cities && formdata.append("city", selectedFilter?.cities);
            selectedState && formdata.append("state", selectedState?.trim());
            try {
                const response = await getTrackerCityPincode(formdata);
                const allOption = [{ value: "", label: "All Service City Pincode" }];
                const transformedData = response?.data?.data?.length > 0 ? response?.data?.data?.map(item => ({
                    value: item.id,
                    label: item.pincode + ", " + item?.area + ", " + item?.city
                })) : [];
                setTrakerDropdown(prevState => ({
                    ...prevState,
                    pincode: [...allOption, ...transformedData],
                }));
            } catch (error) {
                console.log('error', error);
            }
        }
    };

    const downloadFile = (Path) => {
        fetch(Path)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', Path?.split("/")[Path?.split("/")?.length - 1]);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error downloading PDF:', error);
            });
    };
    const downloadCsvFile = async () => {
        try {
            // setIsLoading(true)
            setIsFileDownloading(true)
            const formdata = new FormData();
            selectedFilter?.service_provider && formdata.append("service_provider", selectedFilter?.service_provider);
            selectedFilter?.services && formdata.append("service", selectedFilter?.services);
            selectedFilter?.cities && formdata.append("city", selectedFilter?.cities);
            selectedFilter?.pincode && formdata.append("pincode", selectedFilter?.pincode);
            selectedFilter?.utm_terms && formdata.append("utm_term", selectedFilter?.utm_terms);
            dateRanges?.start && formdata?.append("startDate", start.format('YYYY-MM-DD'))
            dateRanges?.end && formdata?.append("endDate", end.format('YYYY-MM-DD'))
            selectedCsvFields?.length > 0 && formdata.append("feilds", JSON.stringify(selectedCsvFields));
            handleTrackerExportClose();
            const response = await downloadCSV(formdata);
            if (response?.data?.status) {
                downloadFile(response?.data?.filePath)
                // handleTrackerExportClose();
                toast.success("Data Successfully Downloaded!!");
                setSelectedCsvFields([])

            } else {
                toast.error(response?.data?.message)
                // handleTrackerExportClose();
                setSelectedCsvFields([])
            }
            // setIsLoading(false)

            setIsFileDownloading(false)
        } catch (error) {
            console.log('error', error);
            // setIsLoading(false)
            setIsFileDownloading(false)
        }
    };

    /** Fetch Tracker Data */
    const fetchTrakerData = async () => {
        setIsLoading(true)

        try {
            const formData = new FormData()
            formData?.append("limit", limit)
            formData?.append("page", current)
            selectedFilter?.service_provider && formData?.append("service_provider", selectedFilter?.service_provider)
            selectedFilter?.services && formData?.append("service", selectedFilter?.services)
            selectedFilter?.cities && formData?.append("city", selectedFilter?.cities)
            selectedFilter?.pincode && formData?.append("pincode", selectedFilter?.pincode);
            selectedFilter?.utm_terms && formData.append("utm_term", selectedFilter?.utm_terms);
            dateRanges?.start && formData?.append("startDate", start.format('YYYY-MM-DD'))
            dateRanges?.end && formData?.append("endDate", end.format('YYYY-MM-DD'))
            const trakerData = await getTrakerData(formData);
            if (trakerData?.data?.status) {
                setTrakerData(trakerData.data)
            }

        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }

    const onChange = page => {
        setCurrent(page);
    };
    const handleLimitChange = (e) => {
        setCurrent(1);
        setLimit(e)
    }
    /**select csv fields */
    const selectFields = (field) => {
        if (selectedCsvFields?.includes(field)) {
            setSelectedCsvFields(selectedCsvFields?.filter((item) => item != field))
        } else {
            setSelectedCsvFields([...selectedCsvFields, field])
        }
    }


    /**csv download */
    const csvDownload = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to Download All Data?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                showCancelButton: true,
                reverseButtons: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    handleTrackerExportShow()
                } else {
                }

            });
    };

    /**delete Data */
    let count = 10
    let swalCountdownInterval
    const Deleteproject = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const formData = new FormData()
                    formData?.append("id", id)
                    await deleteTrakerData(formData).then(() => fetchTrakerData())
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };
    /** Generate link */
    const handleGenerateLink = (trackerData) => {
        if (!trackerData || Object.keys(trackerData)?.length === 0) {
            return `${URL}`;
        }

        let link = `${URL}?`;
        Object.keys(trackerData)?.forEach((item, index) => {
            if (trackerData[item] != null && item != "id" && item != "is_default_link_generated") {
                link += item + "=" + trackerData[item] + "&";
            }
        });
        return link.slice(0, -1); // Remove the last '&' character
    };

    /**copy link */
    const handleCopyClick = async (text) => {
        try {
            await copy(text);
            toast.success('Copy Successfully')
        } catch (error) {
            toast.error('Copy Unsuccessfully')
        }
    };


    useEffect(() => {
        fetchTrackerDropdown()
        // fetchTrackerCity()
    }, [])
    useEffect(() => {
        fetchUtmTerm()
    }, [selectedFilter.service_provider, selectedFilter.services,dateRanges])
    useEffect(() => {
        fetchTrackerCityPincode()
    }, [selectedFilter?.cities, selectedState])

    useEffect(() => {
        fetchTrakerData()
    }, [limit, current, selectedFilter, dateRanges])
    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3 className="my-1">Affiliation Clicks {trakerData?.total_record ? <span>({trakerData?.total_record})</span> : ""}</h3>
                    <div className="page-heading-right">
                        <div>
                            <DateRangePicker
                                initialSettings={{
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last 30 Days': [
                                            moment().subtract(29, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                                onCallback={handleCallback}
                            >
                                <div
                                    id="reportrange"
                                    className="form-control my-2"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <i className="fa fa-calendar"></i>&nbsp;
                                    <span>{label}</span> <i className="fa fa-caret-down"></i>
                                </div>
                            </DateRangePicker>
                        </div>
                        <SelectPicker
                            cleanable={false}
                            data={limits}
                            value={limit}
                            onChange={handleLimitChange}
                            searchable={false}
                            className="wv-100 my-1 ms-3"
                            placeholder="Select Record"
                        />
                        <Button variant="primary my-1 ms-3" disabled={isFileDownloading} onClick={() => selectedFilter?.service_provider ? handleTrackerExportShow() : csvDownload()}>
                            <i className="bx bx-file"></i> {isFileDownloading ? "Downloding...!" : "Export Logs"}
                        </Button>
                    </div>
                </div>
                <div className="page-content">
                    <Card className="position-relative radiustop-0">
                        {isLoading && <div className="loader table-loader" ></div>}
                        <Card.Header className="pb-0">
                            <Row>
                                <Col lg={3} md={4}>
                                    <Form.Label>Services Provider</Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={trakerDropdown?.service_provider}
                                        value={selectedFilter?.service_provider}
                                        block
                                        onChange={(e) => {
                                            setSelectedFilter({ ...selectedFilter, services: "", service_provider: e });
                                            fetchTrackerServices(e);
                                            setCurrent(1);
                                        }}
                                        className="my-2"
                                        placeholder="Select Services Provider"
                                    />
                                </Col>
                                <Col lg={3} md={4}>
                                    <Form.Label>Services</Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={trakerDropdown?.services}
                                        block
                                        value={selectedFilter?.services}
                                        onChange={(e) => {
                                            setSelectedFilter({ ...selectedFilter, services: e });
                                            setCurrent(1);
                                        }}
                                        className="my-2"
                                        placeholder="Select Services"
                                    />
                                </Col>
                                <Col lg={6} md={4}>
                                    <Form.Label>UTM Term</Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={trakerDropdown?.utm_terms}
                                        block
                                        value={selectedFilter?.utm_terms}
                                        onChange={(e) => {
                                            // console.log('e>>>>>>>>>>>>>>>', e)
                                            setSelectedFilter({ ...selectedFilter, utm_terms: e });
                                            setCurrent(1);
                                        }}
                                        className="my-2"
                                        placeholder="Select UTM Term"
                                    />
                                </Col>
                                <Col lg={3} md={4}>
                                    <Form.Label>Affilation City</Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={trakerDropdown?.cities}
                                        block
                                        value={selectedFilter?.cities}
                                        onChange={(value, event) => { setSelectedState(event?.target?.innerText?.split(",")[1]); setSelectedFilter({ ...selectedFilter, cities: value, pincode: "" }); setCurrent(1); }}
                                        className="my-2"
                                        placeholder="Select Affilation City"
                                    />
                                </Col>
                                <Col lg={3} md={4}>
                                    <Form.Label>Affilation City Pincode</Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={trakerDropdown?.pincode}
                                        block
                                        value={selectedFilter?.pincode}
                                        onChange={(e) => { setSelectedFilter({ ...selectedFilter, pincode: e }); setCurrent(1); }}
                                        className="my-2"
                                        placeholder="Select Affilation City Pincode"
                                    />
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table className="table-bg-primary" bordered striped responsive>
                                <thead>
                                    <tr>
                                        <th width="5%" className="text-center">No</th>
                                        <th width="15%">Service Provider</th>
                                        <th width="15%">Service</th>
                                        <th width="45%">Query Url</th>
                                        <th width="10%" className="text-center">Query Maping</th>
                                        <th width="10%" className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trakerData?.data?.length > 0 ? trakerData?.data?.map((item, index) => {
                                        return <tr key={index}>
                                            <td className="text-center">{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                                            <td>{item?.service_provider}</td>
                                            <td>{item?.service}</td>
                                            <td><p className="break-word dot-line-2 mb-0">{handleGenerateLink(item)}
                                            </p></td>
                                            <td className="text-center">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Link to="#" onClick={() => handleCopyClick(handleGenerateLink(item))} className="btn btn-icon btn-transparent btn-warning">
                                                        <i className='bx bx-copy-alt' ></i>
                                                    </Link>
                                                    <span className='border-start py-2 mx-2'></span>
                                                    <Link to={handleGenerateLink(item)} className="btn btn-icon btn-transparent btn-light" target="_blank">
                                                        <i className='bx bx-link-external' ></i>
                                                    </Link>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Button onClick={() => { setMappingList(item); handleTrackerShow(); }} variant="primary" size="sm" className="btn-icon btn-transparent">
                                                        <i className='bx bx-show'></i>
                                                    </Button>
                                                    {userPermission["Affiliation-Clicks"]?.delete == 1 && <> <span className='border-start py-2 mx-2'></span>
                                                        <Button variant="danger" onClick={() => Deleteproject(item?.id)} size="sm" className="btn-icon btn-transparent">
                                                            <i className='bx bx-trash' ></i>
                                                        </Button>
                                                    </>}
                                                </div>
                                            </td>
                                        </tr>
                                    }) : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className="no-found">
                                                <img src="../../not-found/image.svg" /><p>No Found Affiliation Tracker</p>
                                            </div>
                                        </td>
                                    </tr>}
                                </tbody>
                            </Table>
                            {trakerData?.total_record > limit &&
                                <div className="pagination-custom">
                                    <Pagination
                                        className="pagination-data"
                                        onChange={onChange}
                                        current={current}
                                        total={trakerData?.total_record}
                                        pageSize={limit}
                                        showSizeChanger={false}
                                        showTitle={false}
                                    />
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </div>
                <Modal show={trackerExportshow} scrollable size='md' onHide={handleTrackerExportClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select fileds for export</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <Form.Check
                                    type="checkbox"
                                    className="my-2"
                                    id="select_all"
                                    label="Select All"
                                    onChange={(e) => e.target.checked ? setSelectedCsvFields(trakerDropdown?.fields) : setSelectedCsvFields([])}
                                    checked={trakerDropdown?.fields?.length > 0 && trakerDropdown?.fields?.every(field => selectedCsvFields.includes(field))}
                                />
                            </Col>
                            {trakerDropdown?.fields?.length > 0 &&
                                trakerDropdown?.fields?.map((item, index) => {
                                    return (<Col key={index} md={6}>
                                        <Form.Check
                                            type="checkbox"
                                            className="my-2"
                                            id={item}
                                            label={item}
                                            checked={selectedCsvFields?.includes(item)}
                                            onChange={() => selectFields(item)}
                                        />
                                    </Col>)
                                })}

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary me-2" onClick={handleTrackerExportClose}>Cancel</Button>
                        <Button variant="primary" onClick={downloadCsvFile} disabled={selectedCsvFields?.length <= 0}>Export</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={trackershow} scrollable size='lg' onHide={handleTrackerClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Query Maping List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table-bg-primary" bordered striped responsive>
                            <tbody>
                                {mappingList && Object.keys(mappingList)?.length > 0 &&
                                    Object.keys(mappingList)?.map((item, index) => {
                                        return (
                                            mappingList[item] != null && <tr key={index}>
                                                <th width="30%">{item}</th>
                                                {item != "is_default_link_generated"
                                                    ? <td width="70%">{mappingList[item]}</td>
                                                    : <td width="70%">{mappingList[item] == 0 ? "false" : "true"}</td>}
                                            </tr>)
                                    })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </Layout>
        </>
    )
}

export default AffiliationTracker;