import Cookies from "js-cookie";
import { API } from "../App";

// const token = Cookies.get("fmljwt")
// Affiliation Dashboard

export const getTrakerData = async (formData) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/trakerData", formData, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
};
export const getTrackerDropdown = async () => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.get("/get_tracker_dropdown", { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
};
export const getTrackerServices = async (formData) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/get_tracker_service", formData, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
};
export const getTrackerCity = async (formData) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/get_tracker_city", formData, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
};
export const getTrackerCityPincode = async (formData) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/get_tracker_city_pincode", formData, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
};
export const downloadCSV = async (formData) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/download_tracker_csv", formData, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
};
export const downloadPerformanceCSV = async (formData) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/trakerPerformanceCSV", formData, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
};


export const GetTrackerUtmTerm = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/get_tracker_utmTerm", payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const GetTrackerPerformance = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/trakerPerformanceData", payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const deleteTrakerData = async (payload) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/delete_trakerData", payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
}

export const sendAffiliationNotification = async (payload) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/send-notification", payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

