import React, { useContext, useEffect, useState } from 'react';
import Layout from "../../layout/Layout";
import { Form, Card, Table, Button, Badge, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "rc-pagination";
import { deleteServiceProviderCity, getServiceCityIdWisePincode, getServiceProviderCity, updatePincodeStatus } from '../../service/apis';
import { SelectPicker } from 'rsuite';
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';

const ServiceProviderCity = () => {
    const { userPermission} = useContext(AuthContext)

    const [current, setCurrent] = useState(1);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allServiceProviderCity, setAllServiceProviderCity] = useState([]);
    const [isHidden, setIsHidden] = useState(true);
    const [allPincode, setAllPincode] = useState([]);
    const [selectIndex, setSelectIndex] = useState();
    const [cityName, setCityName] = useState();
    const [limit, setLimit] = useState("25");
    const [id, setId] = useState();


    useEffect(() => {
        fetchServiceProviderCity()
    }, [limit, current])

    const onChange = page => {
        setCurrent(page);
    };

    const CityModalhandleClose = () => {
        setShow(false);
    }
    const CityModalhandleShow = async (cityId, providerId, cityname) => {
        setCityName(cityname)
        setId(providerId)
        setIsLoading(true);
        try {
            const response = await getServiceCityIdWisePincode(cityId, providerId)
            setAllPincode(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
        setShow(true);
    }

    const data = ['25', '50', "100"].map(
        item => ({ label: item, value: item })
    );

    const CityNamehandleShow = (index) => {
        (selectIndex == index) ? setSelectIndex() : setSelectIndex(index)
        setIsHidden(!isHidden);
    };

    //     //Apis

    const fetchServiceProviderCity = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData()
            formData.append('limit', limit)
            formData.append('page', current)
            const response = await getServiceProviderCity(formData)
            setAllServiceProviderCity(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    try {
                        deleteServiceProviderCity(id).then(() => {
                            setCurrent(1)
                            fetchServiceProviderCity()
                        })

                    } catch (error) {
                        console.log(error)
                    } finally {
                        setIsLoading(false);
                    }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };
    const handleChangeStatus = (index) => {
        const updateData = [...allPincode]
        updateData[index].status = !updateData[index].status;
        setAllPincode(updateData)
    }
    const handleSaveStatus = () => {
        setIsLoading(true);
        try {
            const formData = new FormData()
            formData.append("id", id)
            formData.append("pin_code_data", JSON.stringify(allPincode))
            updatePincodeStatus(formData).then(() => { toast.success("Status Update Successfully"); CityModalhandleClose() })

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3 className="my-1">Service Provider City</h3>
                    <div className="page-heading-right">
                        <SelectPicker
                            cleanable={false}
                            data={data}
                            searchable={false}
                            value={limit}
                            onChange={(e) => { setLimit(e); setCurrent(1) }}
                            className="wv-100 my-1 ms-3"
                            placeholder="Select Record"
                        />
                        {userPermission["Service-Provider"]?.add == 1 && <Link to="/service-provider-city/add" className="btn btn-primary my-1 ms-3">Add New</Link>
                        }                    </div>
                </div>
                <div className="page-content">
                    <Card className="radiustop-0">
                        <Card.Body className='position-relative'>
                            {isLoading && <div className="loader table-loader" ></div>}
                            <Table className="table-bg-primary" bordered striped responsive>
                                <thead>
                                    <tr>
                                        <th width="5%" className="text-center">No</th>
                                        <th width="14%">Service</th>
                                        <th width="14%">Service Provider</th>
                                        <th width="10%" className="text-center">Query Maping</th>
                                        <th width="47%">City Name</th>
                                        <th width="8%" className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allServiceProviderCity?.data?.length > 0 && allServiceProviderCity?.data?.map((item, index) => {
                                        return (<tr key={index}>
                                            <td className="text-center">{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                                            <td>{item?.get_affiliation_services?.services}</td>
                                            <td>{item?.get_service_provider_name?.provider}</td>
                                            <td className="text-center">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Link to="/" className="btn btn-icon btn-transparent btn-warning">
                                                        <i className='bx bx-copy-alt' ></i>
                                                    </Link>
                                                    <span className='border-start py-2 mx-2'></span>
                                                    <Button variant="success" size="sm" className="btn-icon btn-transparent">
                                                        <i className='bx bx-link-external' ></i>
                                                    </Button>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        {/* {(index !== selectIndex) ? item?.city_names?.slice(0, 14).map((city, i) => (
                                                            <Badge key={i} bg="primary" className="mx-1 mb-1 wv-100 dot-line-max-12" onClick={() => CityModalhandleShow(city.id, item.id, city.city)}>
                                                                {city.city}
                                                            </Badge>
                                                        ))
                                                            : item?.city_names?.map((city, i) => (
                                                                <Badge key={i} bg="primary" className="mx-1 mb-1 wv-100 dot-line-max-12" onClick={() => CityModalhandleShow(city.id, item.id, city.city)}>
                                                                    {city.city}
                                                                </Badge>
                                                            ))} */}
                                                        {(item?.city_id === "ALL" || item?.city_id === "all" || item?.city_id === "All")
                                                            ?
                                                            <Badge bg="primary" className="mx-1 mb-1 wv-100 dot-line-max-12" >
                                                                {item?.city_id}
                                                            </Badge>
                                                            : (index !== selectIndex) ? item?.city_names?.slice(0, 12).map((city, i) => (
                                                                <Badge key={i} bg="primary" className="mx-1 mb-1 wv-100 dot-line-max-12" onClick={() => CityModalhandleShow(city.id, item.id, city.city)}>
                                                                    {city.city}
                                                                </Badge>
                                                            ))
                                                                : item?.city_names?.map((city, i) => (
                                                                    <Badge key={i} bg="primary" className="mx-1 mb-1 wv-100 dot-line-max-12" onClick={() => CityModalhandleShow(city.id, item.id, city.city)}>
                                                                        {city.city}
                                                                    </Badge>
                                                                ))}

                                                    </div>
                                                    {item?.city_names?.length > 13 && <div className="border-start ms-3 ps-3" onClick={() => CityNamehandleShow(index)}>
                                                        <Button variant="primary" size="sm" className="btn-icon btn-transparent"><i class={index !== selectIndex ? 'bx bx-caret-down':'bx bx-caret-up'} ></i></Button>
                                                    </div>}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Link to={`/service-provider-city/view/${item.id}`} className="btn btn-icon btn-transparent btn-primary">
                                                        <i className='bx bx-show'></i>
                                                    </Link>
                                                    {userPermission["Service-Provider"]?.delete == 1 &&
                                                        <>
                                                        <span className='border-start py-2 mx-2'></span>
                                                        <Button variant="danger" onClick={() => handleDelete(item?.id)} size="sm" className="btn-icon btn-transparent">
                                                            <i className='bx bx-trash' ></i>
                                                        </Button>
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                            <div className="pagination-custom">
                                {allServiceProviderCity?.data?.length > 0 && allServiceProviderCity?.total_record > limit && <Pagination
                                    className="pagination-data"
                                    onChange={onChange}
                                    current={current}
                                    total={allServiceProviderCity.total_record}
                                    pageSize={limit}
                                    showSizeChanger={false}
                                    showTitle={false}
                                />}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <Modal show={show} scrollable size='lg' onHide={CityModalhandleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><span className='text-primary'>{cityName}</span> Pincode</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table-bg-primary" bordered striped responsive>
                            <thead>
                                <tr>
                                    <th width="10%" className="text-center">No</th>
                                    <th width="70%">City Pincode</th>
                                    <th width="20%" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allPincode?.length > 0 ? allPincode?.map((item, index) => {
                                    return (<tr key={index}>
                                        <td className="text-center">{index + 1}</td>
                                        <td>{item.pincode}</td>
                                        <td className="text-center"><Form.Check
                                        disabled={userPermission["Service-Provider"]?.edit!= 1 } type="switch" onChange={() => handleChangeStatus(index)} checked={item.status} /></td>
                                    </tr>)
                                }) : <tr>
                                    <td colSpan="100%" className="p-0">
                                        <div className='no-found'>
                                            <img src="../../not-found/image.svg" />
                                            <p>No Found City Pincodes</p>
                                        </div>
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={CityModalhandleClose}>Close</Button>
                        <Button variant="primary" onClick={handleSaveStatus}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </Layout>
        </>
    )
}

export default ServiceProviderCity