import React, { useContext, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Form, Card, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { deleteLanguage, getLanguage, saveLanguage } from "../../service/apis";
import Swal from "sweetalert2";
import $ from "jquery";
import { AuthContext } from "../../AppContext";

const AffiliationLanguage = () => {
    const { userPermission } = useContext(AuthContext)

    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditId, setEditId] = useState(-1);
    const [allLanguage, setAllLanguage] = useState(false);
    const [validated, setValidated] = useState(false);
    const handleChanges = nextChecked => {
        setChecked(nextChecked);
    };
    useEffect(() => {
        fetchLanguage()
    }, [])
    const fetchLanguage = async () => {
        setIsLoading(true);
        try {
            const response = await getLanguage()
            setAllLanguage(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    //  Add New languages
    const handleNewAdd = () => {
        setEditId(0)
        setAllLanguage((prevLanguages) => [
            {
                lable: "",
                gu: "",
                mr: "",
                ta: "",
                te: "",
                kn: "",
                hi: "",
                or: "",
                bn: "",
                ml: "",
                pa: "",
            },
            ...prevLanguages,
        ]);
    }
    const handleSave = (index) => {

        if (allLanguage[index].lable) {
            setIsLoading(true);
            try {

                const formData = new FormData();

                allLanguage[index].id && formData.append("id", allLanguage[index].id)
                formData.append("lable", allLanguage[index].lable)
                formData.append("gu", allLanguage[index].gu)
                formData.append("mr", allLanguage[index].mr)
                formData.append("ta", allLanguage[index].ta)
                formData.append("te", allLanguage[index].te)
                formData.append("kn", allLanguage[index].kn)
                formData.append("hi", allLanguage[index].hi)
                formData.append("or", allLanguage[index].or)
                formData.append("bn", allLanguage[index].bn)
                formData.append("ml", allLanguage[index].ml)
                formData.append("pa", allLanguage[index].pa)
                saveLanguage(formData)
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false);
                setEditId(-1)
            }

        } else {
            setValidated(true)
        }
    }
    // const handleDelete = (index) => {
    //     setAllLanguage(allLanguage?.length>0&&allLanguage?.filter((item,i)=>i!==index))

    // }

    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id, lable, index) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    setAllLanguage(allLanguage?.length > 0 && allLanguage?.filter((item, i) => i !== index))
                    const formData = new FormData()
                    formData.append("id", id)
                    formData.append("lable", lable)
                    id && deleteLanguage(formData)
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    const handleChange = (e, index, property) => {
        setValidated(false)

        const language = [...allLanguage];
        language[index][property] = e.target.value
        setAllLanguage(language);
    };

    return (
        <>
            <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading backbtn">
                    <h3><Link to="/affiliation" className='btn btn-transparent btn-icon me-2'><i className='bx bx-chevron-left'></i></Link>Affiliation Language</h3>
                    <div className="page-heading-right">
                        {/* <Button variant="primary ms-3 my-1" type="button">Excle Sheet Upload</Button> */}
                        {userPermission["Language"]?.add == 1 && <Button variant="primary ms-3 my-1" type="button" onClick={handleNewAdd} >Add New</Button>
                        }                    </div>
                </div>
                <div className="page-content">
                    <Row className="g-4">
                        {allLanguage?.length > 0 && allLanguage?.map((language, index) => {
                            return (<Col sm={12} key={index}>
                                <Card>
                                    <Card.Header className="border-bottom">
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <h4 className="m-0 fw-bold">{language?.lable}</h4>
                                            <div>
                                                {isEditId == index && <Button variant="primary px-3" onClick={() => handleSave(index)} size="sm">Save</Button>}
                                                {userPermission["Language"]?.edit==1&&(isEditId != index && <Button variant="info" size="sm" onClick={() => setEditId(index)} className="btn-icon-lg  ms-3"><i className='bx bx-pencil'></i></Button>)}
                                                {userPermission["Language"]?.delete==1&&<Button variant="danger" size="sm" onClick={() => handleDelete(language.id, language.lable, index)} className="btn-icon-lg ms-3"><i className='bx bx-x'></i></Button>}
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Form validated={validated} >
                                                <Col sm={12}>
                                                    <Form.Label>Lable</Form.Label>
                                                    <Form.Control required type="text" name="lable" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "lable")} value={language?.lable} className="my-2" />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Lable
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form>

                                            <Col md={4}>
                                                <Form.Label>Bengali</Form.Label>
                                                <Form.Control value={language?.bn} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "bn")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Gujarati</Form.Label>
                                                <Form.Control value={language?.gu} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "gu")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Hindi</Form.Label>
                                                <Form.Control value={language?.hi} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "hi")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Kannada</Form.Label>
                                                <Form.Control value={language?.kn} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "kn")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Malayalm</Form.Label>
                                                <Form.Control value={language?.ml} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "ml")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Marathi</Form.Label>
                                                <Form.Control value={language?.mr} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "mr")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Odia</Form.Label>
                                                <Form.Control value={language?.or} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "or")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Punjabi</Form.Label>
                                                <Form.Control value={language?.pa} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "pa")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Tamil</Form.Label>
                                                <Form.Control value={language?.ta} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "ta")} className="my-2" />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Telugu</Form.Label>
                                                <Form.Control value={language?.te} type="text" disabled={isEditId != index} onChange={(e) => handleChange(e, index, "te")} className="my-2" />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>)
                        })}
                    </Row>
                </div>
            </Layout>
        </>
    )
}

export default AffiliationLanguage;