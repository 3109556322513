import React, { useContext, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Form, Card, Table, Row, Col, Button } from "react-bootstrap";
import SelectPicker from 'rsuite/SelectPicker';
import { Link } from "react-router-dom";
import Pagination from "rc-pagination";
import { affilationProgramStatusUpdate, changeDatabase, deleteAffilationProgramPlace, getAffiliatiPlace, getAffiliationProgramData } from "../../service/apis";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import { AuthContext } from "../../AppContext";


const Affiliation = () => {
    const { userPermission } = useContext(AuthContext)
    const [current, setCurrent] = useState(1);
    const [limit, setLimit] = useState("25");
    const [affiliatiPlace, setAffiliatiPlace] = useState([]);
    const [allProgramData, setAllProgramData] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        // fetchAffiliationProgramData({ page: current, limit })
        fetchAffiliatiPlace()
    }, [])
    useEffect(() => {
        fetchAffiliationProgramData({ page: current, limit, place: selectedPlace })
        // fetchAffiliatiPlace()
    }, [limit, current, selectedPlace])
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const onChange = page => {
        setCurrent(page);
    };

    // Filter Dropdown

    const affiliatiPlaces = [{ label: "All", value: "" }];
    affiliatiPlace?.map((item) =>
        affiliatiPlaces.push({ label: item.place, value: item.id })
    );
    const database = [{ label: "Staging to Live", value: "stagetolive" }, { label: "Live to Staging", value: "livetostage" }]
    const limits = ['25', '50', '100'].map(
        item => ({ label: item, value: item })
    );

    const handlePlaceChange = (e) => {
        setSelectedPlace(e);
        setCurrent(1)
    }
    const handleLimitChange = (e) => {
        setCurrent(1)
        setLimit(e)
    }
    const handleClearFilter = () => {
        setSelectedPlace('');
    }
    //Api Calling

    const fetchAffiliatiPlace = async () => {
        setIsLoading(true);
        try {
            const response = await getAffiliatiPlace()
            setAffiliatiPlace(response?.data?.data?.affiliation_place);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchAffiliationProgramData = async (payload) => {
        setIsLoading(true);
        try {
            const response = await getAffiliationProgramData(payload)
            setAllProgramData(response?.data?.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    // Status Update
    const handleStatusChange = (status, id, key) => {
        affilationProgramStatusUpdate(status.target.checked, id, key).then(() => {
            fetchAffiliationProgramData({ page: current, limit, place: selectedPlace })
            toast.success(" status Update successfully");
        }
        );
    };
    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id, lable, index) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    try {
                        deleteAffilationProgramPlace(id).then(() => fetchAffiliationProgramData({ page: current, limit, place: selectedPlace }))

                    } catch (error) {
                        console.log(error)
                    } finally {
                        setIsLoading(false);
                    }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    const handleChangDatabase = async (e) => {
        setIsLoading(true);
        try {
            const formData = new FormData()
            formData.append("name", e)
            const result = await changeDatabase(formData)
            toast.success("Database Change Successfully")
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const uniquePlaces = new Set();
    const filteredData = [];

    allProgramData?.data?.forEach(item => {
        const placeInfo = item.get_affiliation_place;
        if (placeInfo) {
            if (!placeInfo.deleted_at) {
                const placeName = placeInfo.place;
                if (!uniquePlaces.has(placeName)) {
                    uniquePlaces.add(placeName);
                    filteredData.push(item);
                }
            }
        }
    });


    return (

        <>
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3 className="my-1">Affiliation Program</h3>
                    <div className="page-heading-right">
                        <SelectPicker
                            cleanable={false}
                            data={database}
                            onChange={(e) => handleChangDatabase(e)}
                            className="wv-175 my-1 ms-3"
                            placeholder="Database Update"
                            placement="bottomEnd"
                            disabled={!(userPermission["Affilation-Program"]?.add == 1 || userPermission["Affilation-Program"]?.edit == 1)}
                        />
                        <SelectPicker
                            cleanable={false}
                            data={affiliatiPlaces}
                            onChange={handlePlaceChange}
                            value={selectedPlace}
                            className="wv-125 my-1 ms-3"
                            placeholder="Select Place"
                            placement="bottomEnd"
                        />
                        <SelectPicker
                            cleanable={false}
                            data={limits}
                            // defaultValue="25"
                            value={limit}
                            onChange={handleLimitChange}
                            searchable={false}
                            className="wv-100 my-1 ms-3"
                            placeholder="Select Record"
                        />
                        <Button variant="danger" onClick={handleClearFilter} className="my-1 ms-3">Clear</Button>
                        {userPermission["Affilation-Program"]?.add == 1 && <Link to="/affiliation/add" className="btn btn-primary my-1 ms-3">Add Affiliation</Link>}
                        {userPermission["Affilation-Data"]?.view == 1 && <Link to="/affiliation/data" className="btn btn-primary my-1 ms-3">Add Affiliation Data</Link>}
                        {userPermission["Offer"]?.view == 1 && <Link to="/affiliation/offer" className="btn btn-primary my-1 ms-3">Add Offer</Link>}
                        {userPermission["Language"]?.view == 1 && <Link to="/affiliation/language" className="btn btn-primary my-1 ms-3">Add Language</Link>}
                        {
                            userPermission["Affilation-Image"]?.view == 1 && <Link to="/affiliation/addimages" className="btn btn-primary my-1 ms-3">Add Image</Link>}
                    </div>
                </div>
                <div className="page-content">
                    <Card className="radiustop-0">
                        <Card.Body className="position-relative">
                            {isLoading && <div className="loader table-loader" ></div>}
                            <Table className="table-bg-primary" bordered striped responsive>
                                <thead>
                                    <tr>
                                        <th width="5%" className="text-center">No</th>
                                        <th width="55%">Affiliation Place</th>
                                        <th width="10%" className="text-center">Is New</th>
                                        <th width="10%" className="text-center">Is Website</th>
                                        <th width="10%" className="text-center">Status</th>
                                        <th width="10%" className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData?.length > 0 && filteredData?.map((item, index) => {
                                        return (item?.get_affiliation_place?.place &&
                                            (<tr key={index}>
                                                <td className="text-center">{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                                                <td>{item?.get_affiliation_place?.place}</td>
                                                <td className="text-center"><Form.Check type="switch" disabled={userPermission["Affilation-Program"]?.edit != 1} onChange={(e) => handleStatusChange(e, item?.get_affiliation_place?.id, "is_new")} checked={item?.get_affiliation_place?.is_new == 1} /></td>
                                                <td className="text-center"><Form.Check type="switch" disabled={userPermission["Affilation-Program"]?.edit != 1} onChange={(e) => handleStatusChange(e, item?.get_affiliation_place?.id, "is_web")} checked={item?.get_affiliation_place?.is_web == 1} /></td>
                                                <td className="text-center">
                                                    <Form.Check type="switch"
                                                        disabled={userPermission["Affilation-Program"]?.edit != 1} onChange={(e) => handleStatusChange(e, item?.id)} checked={item?.is_need_to_show} />
                                                </td>
                                                <td className="text-center">
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                    <Link to={`/affiliation/view/${item?.affiliation_place_id}`} className="btn btn-icon btn-transparent btn-primary">
                                                        <i className='bx bx-show'></i>
                                                    </Link>
                                                    {userPermission["Affilation-Program"]?.delete == 1 && 
                                                        <>
                                                            <span className='border-start py-2 mx-2'></span>
                                                            <Button variant="danger" onClick={() => handleDelete(item.id)} size="sm" className="btn-icon btn-transparent">
                                                                <i className='bx bx-trash' ></i>
                                                            </Button>
                                                        </>
                                                    }  
                                                    </div>
                                                </td>
                                            </tr>))
                                    })}
                                </tbody>
                            </Table>
                            <div className="mt-4">
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th width="5%" className="text-center">No</th>
                                            <th width="85%">Affiliation Place</th>
                                            <th width="10%" className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">1</td>
                                            <td>Offers</td>
                                            <td className="text-center">
                                                <Link to="/affiliation/offer" className="btn btn-icon btn-transparent btn-primary"><i className='bx bx-show'></i></Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="pagination-custom">
                                {allProgramData?.total_record > limit && <Pagination
                                    className="pagination-data"
                                    onChange={onChange}
                                    current={current}
                                    total={allProgramData?.total_record}
                                    pageSize={limit}
                                    showSizeChanger={false}
                                    showTitle={false}
                                />}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default Affiliation