import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from "../../layout/Layout";
import { API } from "../../App";
import { SelectPicker } from "rsuite";
import Cookies from "js-cookie";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";

var title = []
var reloadId = [];
const UserView = () => {
    const token = Cookies.get("fmljwt");
    const params = useParams();
    const navigate = useNavigate()
    const [id, setid] = useState({ id: params.id });
    const [user, setUser] = useState([]);
    const [Data, SetData] = useState({
        name: "",
        email: "",
        password: "",
        role: "",
        role_id: "",
        id: ""
    });

    const getData = async (id) => {
        const res = await API.post(`/permission/viwe-user/${id}`, {}, { headers: { authorization: `${token}` } })
        SetData({
            name: res.data.data.name,
            email: res.data.data.email,
            password: res.data.data.password,
            role: res.data.data?.role?.RoleName?.name,
            role_id: res.data.data.role?.role_id,
            id: res.data.data.id
        })
        setid({ id: res.data.data.id })
    }

    const getUserList = async () => {
        const res = await API.post(`/permission/fetch-user`, {}, { headers: { authorization: `${token}` } })
        title = []
        reloadId = []
        res.data.data.map((val, index) => {
            title.push({ label: val.name, value: val.id, })
            reloadId.push(val.id)
        })
        setUser(title)
    }


    useEffect(() => {
        getData(params.id)
        getUserList()
    }, []);

    const myfun = () => {
        toast.success("Copy successfully");
    };

    let count = 10
    let swalCountdownInterval
    const Deleteproject = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const result = await API.post(`/permission/delete-user/${Data.id}`, {}, { headers: { authorization: `${token}` } });
                    navigate("/user")
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };
    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading backbtn">
                    <h3>
                        <Link to="/user" className="btn btn-transparent btn-icon me-2">
                            <i className="bx bx-chevron-left"></i>
                        </Link>
                        Permission View
                    </h3>
                    <div className="page-heading-right">
                        <SelectPicker
                            data={user}
                            defaultValue={params.id}
                            cleanable={false}
                            value={id.id}
                            placement="bottomEnd"
                            className="wv-250 my-1 ms-3 rs_UserView70"
                            placeholder="Select Name"
                            menuClassName="menu-custom wv-250"
                            onChange={(e) => getData(e)}
                        />
                        <Link to={`/user/edit/${id.id}`}>
                            <Button variant="primary ms-3 my-1" value="edit" >
                                Edit
                            </Button>
                        </Link>
                        <Button variant="danger ms-3 my-1 btn-icon-lg" onClick={(i) => Deleteproject()}><i className="bx bx-trash-alt"></i></Button>
                    </div>
                </div>
                <div className="page-content">
                    <Card className="radiustop-0">
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <p className="mb-0 fw-bold">Name</p>
                                        <span>{Data.name}</span>
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <div className="mb-4">
                                        <p className="mb-0 fw-bold">Email</p>
                                        <span>{Data.email}</span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <p className="mb-0 fw-bold">Password</p>
                                        <span>
                                            <CopyToClipboard text={Data.password}>
                                                <Button title={Data.password} variant="outline-success" className="btn-icon me-2" onClick={myfun}><i className='bx bx-copy'></i></Button>
                                            </CopyToClipboard>
                                        </span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <p className="mb-0 fw-bold">Role</p>
                                        <span>{Data.role}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default UserView